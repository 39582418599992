import React, { Component } from "react";
import Table from "../../../components/table/Table";
import { Navigate } from "react-router-dom";
import moment from "moment";
import SwalServices from "../../../services/swalServices/SwalServices";
import ActionContainer from "../../../components/UIComponent/ActionTableComponent/actionContainer";
import DropdownAction from "../../../components/dropdown/DropdownAction";
import MembershipTaskListServices from "../../../services/axiosServices/apiServices/MembershipTaskListServices";
import DropdownSelect from "../../../components/dropdown/Dropdown";
import MembershipTaskListProcess from "./MembershipTaskListProcess";
import {
  CommonValidationMessages,
  CommonSuccessMessages,
} from "../../../utils/Messages";
import {
  isValidForm,
  validate,
} from "../../../utils/validation/CommonValidator";
import SendForApprove from "./SendApproveModal";
import SendPaymentLink from "./SendPaymentLink";
import SendQuotation from "./SendQuotation";
import SendInvoice from "./SendInvoice";
import EscalateModal from "./EscalateModal";
import {
  yearDifference,
  //, monthDifference
} from "../../../utils/DateUtils";
import { getAuthProps } from "../../../utils/AuthenticationLibrary";

export default class MembershipTaskList extends Component {
  constructor(props) {
    super(props);
    this.TaskListServices = new MembershipTaskListServices();
    this.swalServices = new SwalServices();
    this.state = {
      isValidStartUpMember: true,
      adminId: 0,
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        status: 0,
        dateFrom: "",
        dateTo: "",
        filterBySource: 0,

      },
      statuses: [],
      actionDropdown: ['Activate', 'Deactivate', 'Update', 'Delete'],
      totalResultes: "",
      taskList: [],
      isLoading: false,
      isLoadingPayment: false,
      isLoadingQuotation: false,
      isLoadingInvoice: false,
      isLoadingApprove: false,
      isEscalateLodaing: false,
      route: null,
      redirect: null,
      membershipTypes: [],
      transactionTypes: [],
      billingCategories: [],
      membershipRevenueList: [],
      selectedTaskId: 0,
      membershipTypeId: 1,
      sourceList: [],
      users: [],
      tenureList: [],
      storeBasetenureList: [],
      designationList: [],
      tacNameList: [],
      numberOfChapters: [
        { id: 0, name: "0" },
        { id: 1, name: "1" },
        { id: 2, name: "2" },
        { id: 3, name: "3" },
        { id: 4, name: "4" },
        { id: 5, name: "5" },
      ],
      taskDetails: {
        taskId: 0,
        corporateMemberId: 0,
        companyName: "",
        companyUEN: "",
        incorporationDate: "",
        firstName: "",
        lastName: "",
        designation: "",
        email: "",
        contactNo: "",
        billingCategoryId: "",
        updatedBy: 0,
        subscriptionStartDate: "",
        subscriptionEndDate: "",
        lastUpdatedDate: "",
        membershipTypeId: 1,
        membershipRevenueId: 1,
        transactionTypeId: 1,
        IsApprovalRequired: false,
        additionalPromotion: "",
        commentsToMember: "",
        internalNotes: "",
        commentsToMemberInv: "",
        internalNotesInv: "",
        membershipTenureId: 0,
        numberOfChapter: 0,
        createdBy: 0,
        startMembershipOn: "",
        isStartMembership: false,
        poNumber: "",
        quotationNeeded: false,
        isAppliedForRenewal: false,
        startMembership: false,
        comments: "",
        selectedDesignation: [],
        minimumNumberOfChapter: 0,
        membershipTypeName: "",
        availableChapter: 0,
        joinedChapter: 0,
        includedChapters: 0,
        purchasedChapters: 0,
        coCompanyName: "",
        tacFromId: 0,
        tacName: "",
        interestedChaptersId : 0,
      },
      setDropdownOpen: false,
      membershipTaskListProcessModal: false,
      setAddeditModalOpen: false,
      setPaymentLink: false,
      sendQuotation: false,
      sendInvoice: false,
      sendEscalation: false,
      basePriceTable: [],
      allowableDiscountTable: [],
      percentageDiscountTable: [],
      systemCalculatedTable: [],
      interestedChapter : [],
      disablePaymentButton: true,
      disableSubscription: true,
      sendEscalate: {
        firstName: "",
        lastName: "",
        companyName: "",
        companyUEN: "",
        email: "",
        contactNo: "",
        internalNotes: "",
      },
      validationRule: {
        companyName: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "comapany name"
            ),
          },
        ],
        companyUEN: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "company UEN"
            ),
          },
        ],
        // incorporationDate: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace(
        //       "{0}",
        //       "incorporation date"
        //     ),
        //   },
        // ],
        firstName: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "first name"
            ),
          },
        ],
        lastName: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "last name"
            ),
          },
        ],
        // selectedDesignation: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.SelectRequired.replace(
        //       "{0}",
        //       "designation"
        //     ),
        //   },
        // ],
        // email: [
        //   {
        //     type: "email",
        //     message: CommonValidationMessages.FieldRequired.replace(
        //       "{0}",
        //       "email"
        //     ),
        //   },
        // ],
        // contactNo: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace(
        //       "{0}",
        //       "contact number"
        //     ),
        //   },
        // ],
        membershipTypeId: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "membership type"
            ),
          },
        ],
        membershipRevenueId: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "revenue type"
            ),
          },
        ],
        transactionTypeId: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "transaction type"
            ),
          },
        ],
        interestedChaptersId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "interested chapters"
            ),
          },
        ],
      },
      invoiceValidation: {
        membershipTenureId: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "membership tenure"
            ),
          },
        ],

        // numberOfChapter: [
        //   // {
        //   //   type: "number",
        //   //   message: CommonValidationMessages.FieldRequired.replace(
        //   //     "{0}",
        //   //     "no. of chapters"
        //   //   ),
        //   // },
        //   {
        //     type: "minNumber",
        //     compareEle: "minimumNumberOfChapter",
        //     message: 'Please select no. of chapters'
        //   },
        // ],
        // commentsToMemberInv: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace(
        //       "{0}",
        //       "comments"
        //     ),
        //   },
        // ],
        // internalNotesInv: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace(
        //       "{0}",
        //       "internal notes"
        //     ),
        //   },
        // ],
        // startMembershipOn: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace(
        //       "{0}",
        //       "start membership date"
        //     ),
        //   },
        // ],
      },
      validState: {
        isValid: true,
        error: {},
      },
      validStateForInvoice: {
        isValid: true,
        error: {},
      },
      // validStateMemberSub: {
      //     isValid: true,
      //     error: {}
      // },
      // validStatePricingTable: {
      //     isValid: true,
      //     error: {}
      // },
      // validStateInvGen: {
      //     isValid: true,
      //     error: {}
      // },
      // validationRuleMemberSub: {
      //     isValid: true,
      //     error: {}
      // },
      // validationRulePricingTable: {
      //     isValid: true,
      //     error: {}
      // },
      // validationRuleInvGen: {
      //     isValid: true,
      //     error: {}
      // },
      actionDropdown: ["Process", "Delete"],

    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    // this.getAllTaskList();
    this.getAllDropdownsForTaskList();

    let admin = getAuthProps()
    let adminId = admin.adminUserId > 0 ? admin.adminUserId : 0
    this.setState({ adminId: adminId })
  }

  getAllTaskList = (pagination = this.state.pagination) => {
    this.setState({ isLoading: true });
    this.TaskListServices.getAllTaskList(pagination).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        // var tasks = {};
        // tasks.taskId =
        // tasks.corporateMemberId =
        // tasks.companyName =
        // tasks.companyUEN =
        // tasks.fullName =
        // tasks.email =
        // tasks.industry =
        // tasks.source =
        // tasks.registrationDate =
        let details = response.responseItem.responseContent.itemList;
        details.map((key, index) => {
          key.registrationDate = moment(key.registrationDate).format(
            "DD MMM YYYY"
          );

          if (key.source === null || key.source === "") {
            key.source = "-";
          }
          if (key.Email === null || key.Email === "") {
            key.Email = "-";
          }
          if (key.FullName === null || key.FullName === "") {
            key.FullName = "-";
          }
          if (key.CompanyName === null || key.CompanyName === "") {
            key.CompanyName = "-";
          }
          if (key.CompanyUEN === null || key.CompanyUEN === "") {
            key.CompanyUEN = "-";
          }
          if (key.ApplicationStatusName === null || key.ApplicationStatusName === "") {
            key.ApplicationStatusName = "-";
          }
          if (key.registrationDate === null || key.registrationDate === "") {
            key.registrationDate = "-";
          }
          if (key.pic === null || key.pic === "") {
            key.pic = "-";
          }
        });
        this.setState({ taskList: details });
        this.setState({
          totalResultes: response.responseItem.responseContent.totalCount,
        });
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  getAllDropdownsForTaskList = () => {
    this.TaskListServices.getAllDropdownsForTaskList().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let membershipArr = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "MembershipType"
        );
        let transactionArr = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "TransactionType"
        );
        let statuses = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "StatusName"
        );
        let billingCategoryArr = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "BillingCategory"
        );
        let membershipRevenueArr = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "MembershipRevenue"
        );
        let sourceArr = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "CorporateProspectSource"
        );
        let userArr = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Users"
        );
        let tenureArr = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Tenure"
        );
        let tacArr = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "TACNames"
        );

        let designation = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Designation"
        );

        let chapters = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Chapters"
        );

        designation.map(function (item) {
          delete item["dropdownName"];
        });
        designation = designation.map((p) => ({
          value: p.id,
          label: p.name,
        }));

        membershipArr.map(function (item) {
          delete item["dropdownName"];
        });
        transactionArr.map(function (item) {
          delete item["dropdownName"];
        });
        billingCategoryArr.map(function (item) {
          delete item["dropdownName"];
        });
        membershipRevenueArr.map(function (item) {
          delete item["dropdownName"];
        });
        sourceArr.map(function (item) {
          delete item["dropdownName"];
        });
        userArr.map(function (item) {
          delete item["dropdownName"];
        });
        this.setState(
          {
            membershipTypes: membershipArr,
            transactionTypes: transactionArr,
            billingCategories: billingCategoryArr,
            membershipRevenueList: membershipRevenueArr,
            sourceList: sourceArr,
            users: userArr,
            tenureList: tenureArr,
            storeBasetenureList: tenureArr,
            designationList: designation,
            tacNameList: tacArr,
            statuses: statuses,
            interestedChapter: chapters
          },
          () => this.getAllTaskList()
        );
      } else {
        this.swalServices.Error(response.message);
        this.getAllTaskList();
      }
      this.setState({ isLoading: false });
    });
  };

  getTaskByCompanyId = (companyId) => {
    this.TaskListServices.getTaskByCompanyId(companyId).then((response) => {

      let desiredId = response.responseItem.responseContent.membershipTypeId;
      let foundIndex = this.state.membershipTypes.find(
        (item) => item.id === desiredId
      ) ? this.state.membershipTypes.find(
        (item) => item.id === desiredId
      ).id : undefined;

      if (desiredId === foundIndex) {
        if (response.statusCode === 200 && response.responseItem != null) {
          response.responseItem.responseContent.transactionTypeId =
            response.responseItem.responseContent.transactionTypeId === null
              ? 1
              : response.responseItem.responseContent.transactionTypeId;
          let designation = [];
          if (
            response.responseItem.responseContent.designation !== null &&
            response.responseItem.responseContent.designation !== ""
          ) {
            let array =
              response.responseItem.responseContent.designation.split(",");
            array.forEach((topic) => {
              let found = this.state.designationList.find(
                (deg) => deg.label === topic
              );
              if (found) {
                designation.push(found);
              }
              //designation.push(this.state.designationList.find(deg => deg.label === topic));
            });
          }
          this.setState(
            { taskDetails: response.responseItem.responseContent },
            () => {
              if (this.state.taskDetails.purchasedChapters > 0) {
                let newChapterArr = [];
                for (
                  let i = this.state.taskDetails.purchasedChapters;
                  i <= 5;
                  i++
                ) {
                  let chapter = {
                    id: i,
                    name: i,
                  };
                  newChapterArr.push(chapter);
                }
                //this.setState({ numberOfChapters: newChapterArr });
              }
              let data = { ...this.state.taskDetails };
              data.selectedDesignation = designation;
              data.minimumNumberOfChapter =
                this.state.taskDetails.purchasedChapters;
              data.startMembership = !!data.startMembershipOn;
              data.numberOfChapter =
                this.state.taskDetails.purchasedChapters &&
                  this.state.taskDetails.purchasedChapters > 5
                  ? 5
                  : this.state.taskDetails.purchasedChapters;
              this.setState({ taskDetails: data });
              this.getPriceTabelForTaskListProcess();
            },
            () => this.setState({ validState: { isValid: true, error: {} } })
          );
          // let details = this.state.taskDetails;
          // details.numberOfChapter= response.responseItem.responseContent.additionalChapter;
          // this.setState({taskDetails : details});
          this.setState({ setAddeditModalOpen: true });
        } else {
          this.swalServices.Error(response.message);
        }
      } else {
        this.swalServices.Error(
          "This membership is currently a non-member. Please update membership type before processing application."
        );
      }

      this.setState({ isLoading: false });
    });
  };

  getPriceTabelForTaskListProcess = () => {
    let request = {
      MembershipTypeId: this.state.taskDetails.membershipTypeId,
      MembershipRevenueId: this.state.taskDetails.membershipRevenueId,
      CorporateMemberId: this.state.taskDetails.corporateMemberId,
      MembershipTenureId: 1,
      MembershipCategoryId: this.state.taskDetails.transactionTypeId,
      isAppliedForRenewal: this.state.taskDetails.isAppliedForRenewal,
    };
    this.TaskListServices.getPriceTabelForTaskListProcess(request).then(
      (response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          var basePriceTable = response.responseItem.responseContent.filter(
            (drp) => drp.tableName === "BasePriceTable"
          );
          var allowableDiscountTable =
            response.responseItem.responseContent.filter(
              (drp) => drp.tableName === "AllowableDiscountTable"
            );
          var percentageDiscountTable =
            response.responseItem.responseContent.filter(
              (drp) => drp.tableName === "PercentageDiscountTable"
            );
          var systemCalculatedTable =
            response.responseItem.responseContent.filter(
              (drp) => drp.tableName === "SystemCalculatedTable"
            );

          basePriceTable.forEach((element, key) => {
            basePriceTable[key].status = "Ok";
            if (this.state.taskDetails.isAppliedForRenewal === true) {
              basePriceTable[key].entranceFee = 0;
            }
          });
          if (allowableDiscountTable.length === 0) {
            allowableDiscountTable.forEach((element, key) => {
              allowableDiscountTable.push(element);
            });
          }
          // let diff = "";
          let yearDiff = 0;
          let yearDiffForRenew = 0;
          if (this.state.taskDetails.membershipTypeId === 3) {
            // if (!request.subscriptionEndDate && !request.subscriptionStartDate) {
            //   let todayDate = new Date();
            //   let inCorDate = new Date(this.state.taskDetails.incorporationDate);
            //   let Difference_In_Time = todayDate.getTime() - inCorDate.getTime();
            //   let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            //   let Difference_In_Years = Difference_In_Days / 365;
            //   diff = Math.floor(Difference_In_Years);
            // }
            let newtenureArr = [];
            if (!isNaN(Date.parse(this.state.taskDetails.incorporationDate))) {
              let sDate = new Date(this.state.taskDetails.incorporationDate);
              let eDate = new Date();
              yearDiff = eDate.getFullYear() - sDate.getFullYear();
              yearDiff = yearDiff === 0 ? 0 : yearDiff;

              // for (let i = 1; i <= 5 - yearDiff; i++) {
              //   let tenure = {
              //     id: i,
              //     name: `${i} Year`
              //   }
              //   newtenureArr.push(tenure);
              // }
              // this.setState({ tenureList: newtenureArr });

              if (
                this.state.taskDetails.isAppliedForRenewal === true &&
                yearDiff < 5
              ) {
                let eDate = new Date(
                  this.state.taskDetails.subscriptionEndDate
                );
                let currentDate = new Date();
                yearDiffForRenew =
                  eDate.getFullYear() - currentDate.getFullYear();

                for (let i = 1; i <= 5 - yearDiff - yearDiffForRenew; i++) {
                  let tenure = {
                    id: i,
                    name: `${i} Year`,
                  };
                  newtenureArr.push(tenure);
                }
                this.setState({ tenureList: newtenureArr });
              } else {
                for (let i = 1; i <= 5 - yearDiff; i++) {
                  let tenure = {
                    id: i,
                    name: `${i} Year`,
                  };
                  newtenureArr.push(tenure);
                }
                this.setState({ tenureList: newtenureArr });
              }
            }
          } else {
            this.setState({ tenureList: this.state.storeBasetenureList });
          }
          if (percentageDiscountTable.length === 0) {
            basePriceTable.forEach((element, key) => {
              var obj = {
                row: key + 11,
                year: key + 1,
                entranceFee: 0,
                annualFee: 0,
                includedChapter:
                  this.state.taskDetails.membershipTypeId === 5 ? 0 : 1,
                newChapterPrice: 0,
                tableName: "PercentageDiscountTable",
                status: "Ok",
                isDeleted: true,
              };
              if (key + 1 > yearDiff + yearDiffForRenew) {
                obj.isDeleted = false;
              } else {
                obj.isDeleted = true;
              }
              percentageDiscountTable.push(obj);
            });
          }
          if (systemCalculatedTable.length === 0) {
            basePriceTable.forEach((element, key) => {
              var obj = {
                row: key + 16,
                year: key + 1,
                entranceFee: 0,
                annualFee: 0,
                includedChapter: 0,
                newChapterPrice: 0,
                tableName: "SystemCalculatedTable",
                isDeleted: true,
              };
              if (key + 1 > yearDiff + yearDiffForRenew) {
                obj.isDeleted = false;
              } else {
                obj.isDeleted = true;
              }
              systemCalculatedTable.push(obj);
            });
          }
          var forPayment = percentageDiscountTable.filter(
            (arr) =>
              arr.status === "Required Approval" || arr.status === "Rejected"
          );
          if (forPayment.length > 0) {
            this.setState({ disablePaymentButton: true });
          } else {
            this.setState({ disablePaymentButton: false });
          }
          this.setState(
            {
              basePriceTable: basePriceTable,
              allowableDiscountTable: allowableDiscountTable,
              percentageDiscountTable: percentageDiscountTable,
              systemCalculatedTable: systemCalculatedTable,
            },
            () => {
              if (
                this.state.taskDetails.taskId &&
                this.state.taskDetails.taskId > 0
              ) {
                this.getExistingDiscountTableByTaskId(
                  this.state.taskDetails.taskId
                );
              } else {
                this.getCalculatedFinalPrice();
              }
              //this.getCalculatedFinalPrice(allowableDiscountTable);
            }
          );
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      }
    );
  };

  getExistingDiscountTableByTaskId = (taskId) => {
    this.TaskListServices.getExistingDiscountTableByTaskId(taskId).then(
      (response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          var percentageDiscount = response.responseItem.responseContent.filter(
            (drp) => drp.tableName === "PercentageDiscountTable"
          );
          // if (percentageDiscount.length === 0) {
          //   percentageDiscount = [];
          //   basePrice.forEach((element, key) => {
          //     var obj = {
          //       row: key + 11,
          //       year: key + 1,
          //       entranceFee: 0,
          //       annualFee: 0,
          //       includedChapter: 0,
          //       newChapterPrice: 0,
          //       tableName: "PercentageDiscountTable",
          //       status: "Ok",
          //     };
          //     percentageDiscount.push(obj);
          //   });
          // }
          if (response.responseItem.responseContent.length === 5) {
            this.setState(
              { percentageDiscountTable: percentageDiscount },
              () => {
                this.getCalculatedFinalPrice();
              }
            );
          } else {
            this.getCalculatedFinalPrice();
          }
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      }
    );
  };

  getCalculatedFinalPrice = () => {
    this.setState({ isLoading: true });
    var request = [];
    request = this.state.basePriceTable.concat(
      this.state.percentageDiscountTable
    );
    // request.push(this.state.basePriceTable);
    // request.push(this.state.percentageDiscountTable);
    this.TaskListServices.getCalculatedFinalPrice(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState(
          {
            systemCalculatedTable: response.responseItem.responseContent,
          },
          () => {
            var forPayment = this.state.percentageDiscountTable.filter(
              (arr) =>
                arr.status === "Required Approval" || arr.status === "Rejected"
            );
            if (forPayment.length > 0) {
              this.setState({ disablePaymentButton: true });
            } else {
              this.setState({ disablePaymentButton: false });
            }
            this.setAllPricingTables();
          }
        );
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  setAllPricingTables = () => {
    let setBasePriceTable = [...this.state.basePriceTable];
    let setAllowableDiscountTable = [...this.state.allowableDiscountTable];
    let setPercentageDiscountTable = [...this.state.percentageDiscountTable];
    let setSystemCalculatedTable = [...this.state.systemCalculatedTable];

    // let diff = "";
    let yearDiff = 0;
    let yearDiffForRenew = 0;
    if (this.state.taskDetails.membershipTypeId === 3) {
      // if (!this.state.taskDetails.subscriptionEndDate && !this.state.taskDetails.subscriptionStartDate) {
      //   let todayDate = new Date();
      //   let inCorDate = new Date(this.state.taskDetails.incorporationDate);
      //   let Difference_In_Time = todayDate.getTime() - inCorDate.getTime();
      //   let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      //   let Difference_In_Years = Difference_In_Days / 365;
      //   diff = Math.floor(Difference_In_Years);
      // }
      if (!isNaN(Date.parse(this.state.taskDetails.incorporationDate))) {
        let sDate = new Date(this.state.taskDetails.incorporationDate);
        let eDate = new Date();
        yearDiff = eDate.getFullYear() - sDate.getFullYear();
        yearDiff = yearDiff === 0 ? 0 : yearDiff;
      }
      if (this.state.taskDetails.isAppliedForRenewal === true && yearDiff < 5) {
        let eDate = new Date(this.state.taskDetails.subscriptionEndDate);
        let currentDate = new Date();
        yearDiffForRenew = eDate.getFullYear() - currentDate.getFullYear();
      }
    }

    for (let index = 0; index <= 4; index++) {
      if (index + 1 > yearDiff + yearDiffForRenew) {
        setBasePriceTable[index].isDeleted = false;
        setAllowableDiscountTable[index].isDeleted = false;
        setPercentageDiscountTable[index].isDeleted = false;
        setSystemCalculatedTable[index].isDeleted = false;
      } else {
        setBasePriceTable[index].isDeleted = true;
        setAllowableDiscountTable[index].isDeleted = true;
        setPercentageDiscountTable[index].isDeleted = true;
        setSystemCalculatedTable[index].isDeleted = true;
      }
    }

    this.setState({
      basePriceTable: setBasePriceTable,
      allowableDiscountTable: setAllowableDiscountTable,
      percentageDiscountTable: setPercentageDiscountTable,
      systemCalculatedTable: setSystemCalculatedTable,
    });
  };

  onViewOrderDetails = (value) => {
    this.setState({
      route: "https://web.sgtech-test.infixsofttech.com/Articles" + value,
    });
  };

  sendEscalateMailToMember = () => {
    //let data = this.state.taskDetails;

    var request = this.state.sendEscalate;
    request.firstName = this.state.taskDetails.firstName;
    request.lastName = this.state.taskDetails.lastName;
    request.companyName = this.state.taskDetails.companyName;
    request.companyUEN = this.state.taskDetails.companyUEN;
    request.email = this.state.taskDetails.email;
    request.contactNo = this.state.taskDetails.contactNo;
    request.internalNotes = this.state.taskDetails.internalNotes;
    request.adminId = this.state.adminId
    this.setState({ isEscalateLodaing: true });
    this.TaskListServices.sendEscalateMailToMember(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ sendEscalation: true }, () =>
          this.setState({ setAddeditModalOpen: false })
        );
        // this.swalServices.Success("Sended.");
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isEscalateLodaing: false });
    });
  };

  addEditMembershipTask = () => {
    let isValidInput = this.isValidateAllFields();
    if (isValidInput) {
      this.setState({ isLoadingApprove: true });
      var pricingList = [];
      var request = { ...this.state.taskDetails, ...pricingList };
      request.taskId = this.state.selectedTaskId;
      // var forPayment = this.state.percentageDiscountTable.filter(
      //   (arr) => arr.status === "Required Approval" || arr.status === "Rejected"
      // );
      request.IsApprovalRequired = true;
      request.IsPaymentLinkSent = false;
      // if (forPayment.length > 0) {
      //   request.IsApprovalRequired = true;
      // }
      // else {
      //   request.IsApprovalRequired = false;
      // }
      request.pricingList = this.state.systemCalculatedTable.concat(
        this.state.percentageDiscountTable,
        this.state.basePriceTable
      );
      if (
        request.selectedDesignation &&
        request.selectedDesignation.length > 0
      ) {
        var arr1 = request.selectedDesignation.map(function (item) {
          delete item.bad;
          return item.label;
        });
        request.designation = arr1.join([","]);
      } else {
        request.designation = "";
      }
      if (request.membershipTypeId != null && request.membershipTypeId > 0) {
        let found = this.state.membershipTypes.find(
          (x) => x.id === request.membershipTypeId
        );
        if (found) {
          request.membershipTypeName = found.name;
        } else {
          request.membershipTypeName = "";
        }
      }
      request.adminID = this.state.adminId
      this.TaskListServices.addEditMembershipTaskAndSendForApproval(
        request
      ).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          //window.location.replace('https://web.sgtech-test.infixsofttech.com//membership/MembershipPayment/' + response.responseItem.responseContent.keyId)
          this.setState({ setSendApprove: true }, () => {
            this.setState({ setAddeditModalOpen: false });
            this.getAllTaskList();
          });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoadingApprove: false });
      });
    }
  };

  getPriceTabelByCorporateMemberId = (companyId) => {
    this.TaskListServices.getPriceTabelByCorporateMemberId(companyId).then(
      (response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          var basePriceTable = response.responseItem.responseContent.filter(
            (drp) => drp.tableName === "BasePriceTable"
          );
          var allowableDiscountTable =
            response.responseItem.responseContent.filter(
              (drp) => drp.tableName === "AllowableDiscountTable"
            );
          var percentageDiscountTable =
            response.responseItem.responseContent.filter(
              (drp) => drp.tableName === "PercentageDiscountTable"
            );
          var systemCalculatedTable =
            response.responseItem.responseContent.filter(
              (drp) => drp.tableName === "SystemCalculatedTable"
            );
          // var obj = {
          //   row: 1,
          //   year: 1,
          //   EntranceFee: 0,
          //   AnnualFee: 0,
          //   newChapterPrice: 0,
          //   tableName: "",
          // };

          if (allowableDiscountTable.length === 0) {
            basePriceTable.forEach((element, key) => {
              allowableDiscountTable.push(element);
            });
          }
          if (percentageDiscountTable.length === 0) {
            basePriceTable.forEach((element, key) => {
              percentageDiscountTable.push(element);
            });
          }
          if (systemCalculatedTable.length === 0) {
            basePriceTable.forEach((element, key) => {
              systemCalculatedTable.push(element);
            });
          }
          this.setState({
            basePriceTable: basePriceTable,
            allowableDiscountTable: basePriceTable,
            percentageDiscountTable: percentageDiscountTable,
            systemCalculatedTable: systemCalculatedTable,
          });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      }
    );
  };
  CheckIsValidStartupMember = (isPaymentLink = false) => {
    if (this.state.taskDetails.membershipTypeId === 3) {
      let rules = { ...this.state.validationRule };
      let invoiceRules = { ...this.state.invoiceValidation };
      let newruleincorporationDate = [
        {
          type: "require",
          message: CommonValidationMessages.FieldRequired.replace(
            "{0}",
            "incorporation date"
          ),
        },
      ];
      rules.incorporationDate = newruleincorporationDate;
      invoiceRules.incorporationDate = newruleincorporationDate;
      this.setState(
        { validationRule: rules, invoiceValidation: invoiceRules },
        () => {
          if (!isNaN(Date.parse(this.state.taskDetails.incorporationDate))) {
            let sDate = new Date(this.state.taskDetails.incorporationDate);
            let eDate = new Date();
            let yearDiff = eDate.getFullYear() - sDate.getFullYear();
            yearDiff = yearDiff === 0 ? 1 : yearDiff;
            if (this.state.taskDetails.membershipTypeId === 3 && yearDiff > 4) {
              this.setState({ isValidStartUpMember: false }, () => {
                this.swalServices.Warning(
                  "Company’s incorporation date is greater than 5 years ago."
                );
                if (isPaymentLink === true) {
                  this.SendPaymentLinkToMember();
                } else {
                  this.SendQuotationtToMember();
                }
              });
            } else {
              if (isPaymentLink === true) {
                this.setState({ isValidStartUpMember: true }, () => {
                  this.SendPaymentLinkToMember();
                });
              } else {
                this.setState({ isValidStartUpMember: true }, () => {
                  this.SendQuotationtToMember();
                });
              }
            }
          } else {
            if (isPaymentLink === true) {
              this.setState({ isValidStartUpMember: true }, () => {
                this.SendPaymentLinkToMember();
              });
            } else {
              this.setState({ isValidStartUpMember: true }, () => {
                this.SendQuotationtToMember();
              });
            }
          }
        }
      );
    } else {
      let valRule = { ...this.state.validationRule };
      let invoiceValRule = { ...this.state.invoiceValidation };
      if (valRule.incorporationDate) {
        delete valRule.incorporationDate;
      }
      if (invoiceValRule.incorporationDate) {
        delete invoiceValRule.incorporationDate;
      }
      this.setState({
        validationRule: valRule,
        invoiceValidation: invoiceValRule,
      });
      this.setState({ isValidStartUpMember: true }, () => {
        if (isPaymentLink === true) {
          this.SendPaymentLinkToMember();
        } else {
          this.SendQuotationtToMember();
        }
      });
    }
  };
  addEditMembershipTaskAndSendPaymentLink = () => {
    this.swalServices
      .Confirm(
        CommonSuccessMessages.Confirm_Payment.replace("{0}", "Payment Link"),
        CommonSuccessMessages.Send_Payment_Link.replace("{0}", "Payment Link"),
        CommonSuccessMessages.Yes_Text,
        CommonSuccessMessages.No_Text
      )
      .then((response) => {
        let resp = response;
        if (resp === true) {
          this.CheckIsValidStartupMember(true);
        }
      });
  };

  SendPaymentLinkToMember = () => {
    let isValidInput = this.isValidateAllFields();
    if (isValidInput && this.state.isValidStartUpMember) {
      var pricingList = [];
      var request = { ...this.state.taskDetails, ...pricingList };
      request.taskId = this.state.selectedTaskId;
      // request.push(this.state.systemCalculatedTable.concat(
      //   this.state.percentageDiscountTable
      // ));
      // request.pricingList = [];
      request.pricingList = this.state.systemCalculatedTable.concat(
        this.state.percentageDiscountTable,
        this.state.basePriceTable
      );
      // request.pricingList.push(this.state.basePriceTable);
      // request.pricingList.push(this.state.percentageDiscountTable);
      // request.percentageDiscountTable = this.state.percentageDiscountTable;
      request.IsApprovalRequired = false;
      request.IsPaymentLinkSent = true;

      if (
        request.selectedDesignation &&
        request.selectedDesignation.length > 0
      ) {
        var arr1 = request.selectedDesignation.map(function (item) {
          delete item.bad;
          return item.label;
        });
        request.designation = arr1.join([","]);
      } else {
        request.designation = "";
      }
      this.setState({ isLoadingPayment: true });
      request.adminId = this.state.adminId
      this.TaskListServices.addEditMembershipTaskAndSendPaymentLink(
        request
      ).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          // window.location.replace('https://web.sgtech-test.infixsofttech.com//membership/MembershipPayment/' + response.responseItem.responseContent.keyId)
          // this.setState({ setAddeditModalOpen: false }, () =>
          //   this.getAllTaskList()
          // );
          //this.swalServices.Success(
          //  SuccessMessage.Update_Success.replace("{0}", "Task")
          //);
          // this.addEditMembershipTask()
          this.getAllTaskList()
          this.setState({ setPaymentLink: true }, () =>
            this.setState({ setAddeditModalOpen: false })
          );
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoadingPayment: false });
      });
    }
  };

  sendInvoiceEmailToCorporateMember = () => {
    let isValidInput = this.isValidateAllFieldsForInvoice();
    if (isValidInput) {
      this.setState({ isLoadingInvoice: true });
      var pricingList = [...this.state.systemCalculatedTable];
      var request = { ...this.state.taskDetails };
      var priceObj = pricingList.find(
        (price) => price.year === request.membershipTenureId
      );
      request.year = priceObj.year;
      request.entranceFee = priceObj.entranceFee;
      let validationYear = "";
      if (request.membershipTypeId === 3) {
        request.annualFee = 0;
        if (request.subscriptionEndDate && request.subscriptionStartDate) {
          if (
            !isNaN(Date.parse(request.subscriptionStartDate)) &&
            !isNaN(Date.parse(request.subscriptionEndDate))
          ) {
            let sDate = new Date(request.subscriptionStartDate);
            let eDate = new Date(request.subscriptionEndDate);
            yearDifference = eDate.getFullYear() - sDate.getFullYear();
            yearDifference = yearDifference === 0 ? 1 : yearDifference;
            validationYear = yearDifference;
            pricingList.forEach((priceObj, PriceIndex) => {
              if (
                priceObj.year > yearDifference &&
                priceObj.year <= request.membershipTenureId
              ) {
                request.annualFee = request.annualFee + priceObj.annualFee;
              }
            });
          }
        } else {
          pricingList.forEach((priceObj, PriceIndex) => {
            if (priceObj.year <= request.membershipTenureId) {
              request.annualFee = request.annualFee + priceObj.annualFee;
            }
          });
        }
      } else {
        request.annualFee = priceObj.annualFee;
      }
      //request.annualFee = priceObj.annualFee;
      request.includedChapter = priceObj.includedChapter;
      request.additionalChapter = request.numberOfChapter;
      request.newChapterPrice = priceObj.newChapterPrice;
      request.tableName = priceObj.tableName;
      request.additionalChapterPrice = priceObj.newChapterPrice;
      request.firstName = this.state.taskDetails.firstName;
      request.lastName = this.state.taskDetails.lastName;
      request.companyName = this.state.taskDetails.companyName;
      request.email = this.state.taskDetails.email;
      request.commentsToMember = this.state.taskDetails.commentsToMember;
      request.additionalPromotion = this.state.taskDetails.additionalPromotion;
      request.internalNotes = this.state.taskDetails.internalNotes;

      if (
        request.selectedDesignation &&
        request.selectedDesignation.length > 0
      ) {
        var arr1 = request.selectedDesignation.map(function (item) {
          delete item.bad;
          return item.label;
        });
        request.designation = arr1.join([","]);
      } else {
        request.designation = "";
      }
      if (request.year > validationYear) {
        this.TaskListServices.sendInvoiceEmailToCorporateMember(request).then(
          (response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
              // this.swalServices.Success(SuccessMessage.Send_Success.replace("{0}", "Invoice"), () => this.setState({ setAddeditModalOpen: false }));
              this.setState({ sendInvoice: true }, () =>
                this.setState({ setAddeditModalOpen: false })
              );
            } else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isLoadingInvoice: false });
          }
        );
      } else {
        this.swalServices.Error(
          "Can't select year of membership less than the purchased year"
        );
        this.setState({ sendQuotation: true }, () =>
          this.setState({ setAddeditModalOpen: false })
        );
      }
    }
  };

  sendQuotationEmailToCorporateMember = () => {
    this.CheckIsValidStartupMember(false);
  };

  SendQuotationtToMember = () => {
    let isValidInput = this.isValidateAllFieldsForInvoice();
    if (isValidInput && this.state.isValidStartUpMember === true) {
      this.setState({ isLoadingQuotation: true });
      var pricingList = [...this.state.systemCalculatedTable];
      var request = { ...this.state.taskDetails };
      var priceObj = pricingList.find(
        (price) => price.year === request.membershipTenureId
      );
      request.year = priceObj.year;
      request.entranceFee = priceObj.entranceFee;
      let yearDifference = "";
      // let validationYear = "";
      // let diff = "";
      if (request.membershipTypeId === 3) {
        request.annualFee = 0;
        // if (request.subscriptionEndDate && request.subscriptionStartDate) {
        //   if (!isNaN(Date.parse(request.subscriptionStartDate)) && !isNaN(Date.parse(request.subscriptionEndDate))) {
        //     let sDate = new Date(request.subscriptionStartDate);
        //     let eDate = new Date(request.subscriptionEndDate);
        //     yearDifference = eDate.getFullYear() - sDate.getFullYear();
        //     yearDifference = yearDifference === 0 ? 1 : yearDifference;
        //     validationYear = yearDifference
        //     // Count no. of days
        //     let inCorDate = new Date(request.incorporationDate);
        //     let Difference_In_Time = eDate.getTime() - inCorDate.getTime();
        //     let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        //     let Difference_In_Years = Difference_In_Days / 365;
        //     diff = Math.floor(Difference_In_Years);
        //     // diff = eDate.getFullYear() - inCorDate.getFullYear();
        //     pricingList.forEach((priceObj, PriceIndex) => {
        //       if (priceObj.year > yearDifference && priceObj.year <= request.membershipTenureId) {
        //         request.annualFee = request.annualFee + priceObj.annualFee;
        //       }
        //     });
        //   }
        // }
        if (!isNaN(Date.parse(request.incorporationDate))) {
          let sDate = new Date(request.incorporationDate);
          let eDate = new Date();
          yearDifference = eDate.getFullYear() - sDate.getFullYear();
          yearDifference = yearDifference === 0 ? 1 : yearDifference;

          if (request.isAppliedForRenewal === true && yearDifference < 5) {
            let eDate = new Date(this.state.taskDetails.subscriptionEndDate);
            let currentDate = new Date();
            let yearDiffForRenew =
              eDate.getFullYear() - currentDate.getFullYear();

            pricingList.forEach((priceObj, PriceIndex) => {
              if (
                PriceIndex > yearDifference - 1 + yearDiffForRenew &&
                priceObj.year <=
                yearDifference + request.year + yearDiffForRenew
              ) {
                request.annualFee = request.annualFee + priceObj.annualFee;
              }
            });
          } else {
            pricingList.forEach((priceObj, PriceIndex) => {
              if (
                PriceIndex > yearDifference - 1 &&
                priceObj.year <= yearDifference + request.year
              ) {
                request.annualFee = request.annualFee + priceObj.annualFee;
              }
            });
          }
        } else {
          pricingList.forEach((priceObj, PriceIndex) => {
            if (priceObj.year <= request.membershipTenureId) {
              request.annualFee = request.annualFee + priceObj.annualFee;
            }
          });
        }
        // if (request.membershipTypeId === 3) {
        //   if (request.subscriptionEndDate === null && request.subscriptionStartDate === null) {
        //     let todayDate = new Date();
        //     let inCorDate = new Date(request.incorporationDate);
        //     // diff = todayDate.getFullYear() - inCorDate.getFullYear();
        //     let Difference_In_Time = todayDate.getTime() - inCorDate.getTime();
        //     let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        //     let Difference_In_Years = Difference_In_Days / 365;
        //     diff = Math.floor(Difference_In_Years);
        //   }
        // }
      } else {
        request.annualFee = priceObj.annualFee;
      }
      request.includedChapter = priceObj.includedChapter;
      request.additionalChapter = request.numberOfChapter;
      request.newChapterPrice = priceObj.newChapterPrice * request.year;
      request.tableName = priceObj.tableName;
      request.additionalChapterPrice = priceObj.newChapterPrice;
      request.firstName = this.state.taskDetails.firstName;
      request.lastName = this.state.taskDetails.lastName;
      request.companyName = this.state.taskDetails.companyName;
      request.email = this.state.taskDetails.email;
      request.commentsToMember = this.state.taskDetails.commentsToMember;
      request.additionalPromotion = this.state.taskDetails.additionalPromotion;
      request.internalNotes = this.state.taskDetails.internalNotes;
      request.coCompanyName = this.state.taskDetails.coCompanyName;
      request.adminId = this.state.adminId

      request.startMembershipOn = this.state.taskDetails.startMembership
        ? this.state.taskDetails.startMembershipOn
        : null;
      // request.startMembership = this.state.taskDetails.startMembership ? true : false;
      request.corporateMemberId = this.state.taskDetails.corporateMemberId;
      request.taskId = this.state.taskDetails.taskId;

      request.pricingList = this.state.systemCalculatedTable.concat(
        this.state.percentageDiscountTable,
        this.state.basePriceTable
      );

      if (
        request.selectedDesignation &&
        request.selectedDesignation.length > 0
      ) {
        var arr1 = request.selectedDesignation.map(function (item) {
          delete item.bad;
          return item.label;
        });
        request.designation = arr1.join([","]);
      } else {
        request.designation = "";
      }
      if (request.year > 0) {
        if (request.year > 0) {
          this.TaskListServices.sendQuotationEmailToCorporateMember(
            request
          ).then((response) => {
            // if (xyz > request.year && request.year ) {
            if (response.statusCode === 200 && response.responseItem != null) {
              let reset = this.state.taskDetails;
              reset.commentsToMemberInv = "";
              reset.minimumNumberOfChapter = 0;
              reset.internalNotesInv = "";
              this.setState({ taskDetails: reset });
              // this.swalServices.Success(SuccessMessage.Send_Success.replace("{0}", "Quotation"), () => this.setState({ setAddeditModalOpen: false }));
              this.getAllTaskList()
              this.setState({ sendQuotation: true }, () =>
                this.setState({ setAddeditModalOpen: false })
              );
            } else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isLoadingQuotation: false });
          });
        } else {
          this.swalServices.Error(
            "Can't select year of membership less than the purchased year"
          );
          this.setState({ isLoadingQuotation: false });
        }
      } else {
        this.swalServices.Error(
          "Cant select membership years less than the incorporation date"
        );
        this.setState({ isLoadingQuotation: false });
      }
    }
  };
  validateField = (key) => {
    const newValidState = validate(
      key,
      this.state.taskDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };

  isValidateAllFields = () => {
    let valRule = { ...this.state.validationRule };
    if (
      this.state.taskDetails?.membershipTypeId > 0 &&
      this.state.taskDetails?.membershipTypeId === 5
    ) {
      let tacFromIdValRule = [
        {
          type: "require",
          message: CommonValidationMessages.SelectRequired.replace(
            "{0}",
            "TAC from type"
          ),
        },
      ];
      valRule.tacFromId = tacFromIdValRule;
      if (
        this.state.taskDetails.tacFromId > 0 &&
        this.state.taskDetails.tacFromId === 1
      ) {
        let tacNameValRule = [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "TAC name"
            ),
          },
        ];
        valRule.tacName = tacNameValRule;
      }
    }

    const newValidState = isValidForm(
      this.state.taskDetails,
      valRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

  validateFieldForInvoice = (key) => {
    const newValidState = validate(
      key,
      this.state.taskDetails,
      this.state.invoiceValidation,
      this.state.validStateForInvoice
    );
    this.setState({ validStateForInvoice: newValidState });
  };

  isValidateAllFieldsForInvoice = () => {
    const newValidState = isValidForm(
      this.state.taskDetails,
      this.state.invoiceValidation,
      this.state.validStateForInvoice
    );
    this.setState({ validStateForInvoice: newValidState });
    return newValidState.isValid;
  };

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getAllTaskList(newPagination);
  };

  setFilterParameters = (id, drpIdentity) => {
    let detail = this.state.taskDetails;
    if (drpIdentity === "billingCategories") {
      detail["billingCategoryId"] = id;
      //this.validateField("resourceTypeId");
    } else if (drpIdentity === "updatedBy") {
      detail["updatedBy"] = id;
    } else if (drpIdentity === "membershipTypes") {
      detail["membershipTypeId"] = id === 0 ? 1 : id;
      this.getPriceTabelForTaskListProcess();
      this.validateField("membershipTypeId");
    } else if (drpIdentity === "membershipRevenue") {
      detail["membershipRevenueId"] = id === 0 ? 1 : id;
      this.getPriceTabelForTaskListProcess();
      this.validateField("membershipRevenueId");
    } else if (drpIdentity === "transactionTypes") {
      detail["transactionTypeId"] = id === 0 ? 1 : id;
      this.getPriceTabelForTaskListProcess();
      this.validateField("transactionTypeId");
    } else if (drpIdentity === "membershipTenure") {
      detail["membershipTenureId"] = id;
      this.validateFieldForInvoice("membershipTenureId");
    } else if (drpIdentity === "chapters") {
      if (id !== 0 || id < 0) {
        detail["numberOfChapter"] = id;
        //this.validateFieldForInvoice("numberOfChapter");
      } else {
        detail["numberOfChapter"] = id;
        //this.validateFieldForInvoice("numberOfChapter");
      }
    } else if (drpIdentity === "TACFromType") {
      detail["tacFromId"] = id;
      detail["tacName"] =
        id > 1 && this.state.tacNameList.find((x) => x.id === id)
          ? this.state.tacNameList?.find((x) => x.id === id)?.name
          : "";
    }
    if (drpIdentity === "InterestedChapter") {
      detail["interestedChaptersId"] = id;
      this.validateField("interestedChaptersId");
    }
    this.setState({ taskDetails: { ...detail } });
  };

  setFilterParametersForList = (id, drpIdentity) => {
    let detail = this.state.pagination;
    detail.statusId = id;
    if (drpIdentity === "sourceFilter") {
      detail["filterBySource"] = id;
    }
    this.setState({ pagination: { ...detail } });
    this.getAllTaskList();
  };

  searchByName = (value) => {
    let detail = this.state.pagination;
    detail.searchText = "";
    this.setState({ pagination: { ...detail } });
    if (value.length > 2) {
      detail.pageNo = 1;
      detail.pageSize = 10;
      detail.searchText = value;
      this.setState({ pagination: { ...detail } });
    }
    this.getAllTaskList();
  };

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.pagination;
    let fulldate = (detail[name] = value === "" ? null : value);
    let date = new Date(fulldate); //1/1/1753 12:00:00 AM and 12/31/9999 11:59:59 PM
    let startLimit = new Date("1/1/1753 12:00:00 AM");
    let endLimit = new Date("12/31/9999 11:59:59 PM");
    detail[name] = value;
    this.setState({ pagination: { ...detail } }, () => {
      if (startLimit < date && date < endLimit) {
        this.getAllTaskList();
      } else if (fulldate === null) {
        this.getAllTaskList();
      }
    });
  }

  handleChangeInPopup(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.taskDetails;
    detail[name] = value;

    this.setState({ taskDetails: { ...detail } }, () => {
      if (name === "incorporationDate") {
        let newtenureArr = [];
        if (!isNaN(Date.parse(this.state.taskDetails.incorporationDate))) {
          let sDate = new Date(this.state.taskDetails.incorporationDate);
          let eDate = new Date();
          let yearDifference = eDate.getFullYear() - sDate.getFullYear();

          if (
            this.state.taskDetails.isAppliedForRenewal === true &&
            yearDifference < 5
          ) {
            let eDate = new Date(this.state.taskDetails.subscriptionEndDate);
            let currentDate = new Date();
            let renewYearDiff = eDate.getFullYear() - currentDate.getFullYear();

            for (let i = 1; i <= 5 - yearDifference - renewYearDiff; i++) {
              let tenure = {
                id: i,
                name: `${i} Year`,
              };
              newtenureArr.push(tenure);
            }
            this.setState({ tenureList: newtenureArr }, () =>
              this.getPriceTabelForTaskListProcess()
            );
          } else {
            for (let i = 1; i <= 5 - yearDifference; i++) {
              let tenure = {
                id: i,
                name: `${i} Year`,
              };
              newtenureArr.push(tenure);
            }
            this.setState({ tenureList: newtenureArr }, () =>
              this.getPriceTabelForTaskListProcess()
            );
          }
        } else {
          this.setState({ tenureList: this.state.storeBasetenureList }, () =>
            this.getPriceTabelForTaskListProcess()
          );
        }
      }
    });
  }

  handleChangeInPriceTable(value, name, year, tableName) {
    var table = this.state.percentageDiscountTable;
    var allowable = this.state.allowableDiscountTable;
    // var allowableValue = Number(allowable[year][name]);
    var approvalStatus = [];
    value = isNaN(value) ? 0 : Number(value);
    if (value > 100) {
      value = 100;
    }
    // if (tableName === "PercentageDiscountTable") {
    table[year][name] = value;
    if (table[year]["annualFee"] > allowable[year]["annualFee"]) {
      approvalStatus.push("Required Approval");
    } else {
      approvalStatus.push("Ok");
    }
    if (table[year]["entranceFee"] > allowable[year]["entranceFee"]) {
      approvalStatus.push("Required Approval");
    } else {
      approvalStatus.push("Ok");
    }
    if (table[year]["includedChapter"] > allowable[year]["includedChapter"]) {
      approvalStatus.push("Required Approval");
    } else {
      approvalStatus.push("Ok");
    }
    if (table[year]["newChapterPrice"] > allowable[year]["newChapterPrice"]) {
      approvalStatus.push("Required Approval");
    } else {
      approvalStatus.push("Ok");
    }
    if (approvalStatus.indexOf("Required Approval") > -1) {
      table[year]["status"] = "Required Approval";
    } else {
      table[year]["status"] = "Ok";
    }
    this.setState({ percentageDiscountTable: table }, () =>
      this.getCalculatedFinalPrice()
    );
  }

  onDropDownChange = (index) => {
    this.setState({ setDropdownOpen: true });
    this.setState({ setDropdownIndex: index });
  };

  handleChangeCheckBox = (e) => {
    const { name, checked } = e.target;

    let details = this.state.taskDetails;
    if (checked) {
      details.quotationNeeded = true;
    } else {
      details.quotationNeeded = false;
    }
    this.setState({ taskDetails: details }, () => {
      if (
        this.state.taskDetails.quotationNeeded === true &&
        this.state.taskDetails.membershipTypeId === 3
      ) {
        let newtenureArr = [];
        if (!isNaN(Date.parse(this.state.taskDetails.incorporationDate))) {
          let sDate = new Date(this.state.taskDetails.incorporationDate);
          let eDate = new Date();
          let yearDifference = eDate.getFullYear() - sDate.getFullYear();

          // for (let i = 1; i <= 5 - yearDifference; i++) {
          //   let tenure = {
          //     id: i,
          //     name: `${i} Year`
          //   }
          //   newtenureArr.push(tenure);
          // }
          // this.setState({ tenureList: newtenureArr });

          if (
            this.state.taskDetails.isAppliedForRenewal === true &&
            yearDifference < 5
          ) {
            let eDate = new Date(this.state.taskDetails.subscriptionEndDate);
            let currentDate = new Date();
            let renewYearDiff = eDate.getFullYear() - currentDate.getFullYear();

            for (let i = 1; i <= 5 - yearDifference - renewYearDiff; i++) {
              let tenure = {
                id: i,
                name: `${i} Year`,
              };
              newtenureArr.push(tenure);
            }
            this.setState({ tenureList: newtenureArr });
          } else {
            for (let i = 1; i <= 5 - yearDifference; i++) {
              let tenure = {
                id: i,
                name: `${i} Year`,
              };
              newtenureArr.push(tenure);
            }
            this.setState({ tenureList: newtenureArr });
          }
        }
      } else {
        this.setState({ tenureList: this.state.storeBasetenureList });
      }
    });
  };

  handleChangeMembershipOnCheckBox = (e) => {
    const { name, checked } = e.target;

    let details = this.state.taskDetails;
    if (checked) {
      details.startMembership = true;
    } else {
      details.startMembership = false;
    }
    this.setState({ taskDetails: details });
  };

  selectTask = (value, e) => {
    e.stopPropagation();
    if (value > 0) {
      this.getTaskByCompanyId(value);
      // this.getPriceTabelByCorporateMemberId(value);
      // this.getPriceTabelForTaskListProcess();
    }
  };

  handleChangeMultiDropdown(selected, identity) {
    var profile = this.state.taskDetails;
    if (identity === "Designation_TaskList") {
      profile.selectedDesignation = selected;

      this.setState({ taskDetails: profile });
    }
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ setDropdownOpen: false });
    }
  }

  actionClick = (index, value, option, event) => {
    if (option === "Process") {
      var id = this.state.taskList[index].corporateMemberId;
      this.setState(
        { selectedTaskId: this.state.taskList[index].taskId },
        () => {
          this.selectTask(id, event);
        }
      );
    } else if (option === "Delete") {
      this.deleteTaskListDetailsByTaskId(value);
    }

    // if(value && value > 0){
    //     this.getTaskByCompanyId(value);
    // }
  };
  deleteTaskListDetailsByTaskId = (companyId) => {
    this.TaskListServices.deleteTaskListDetailsByTaskId(companyId).then(
      (response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.swalServices.Success(
            "Membership task list entry deleted successfully."
          );
          let resetpag = {
            pageNo: 1,
            pageSize: 10,
            orderByColumn: "",
            orderFlag: 0,
            searchText: "",
            statusId: 0,
            dateFrom: null,
            dateTo: null,
            filterBySource: 0,
          };
          this.setState({ pagination: resetpag }, () => {
            this.getAllTaskList();
          });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      }
    );
  };
  actions = (element, index, value) => {
    return element !== "corporateMemberId" ? null : (
      <td>
        <ActionContainer>
          <DropdownAction
            key={index}
            value={value}
            id={index}
            index={index}
            options={this.state.actionDropdown}
            align="right"
            onOptionClick={this.actionClick.bind(this)}
          />
        </ActionContainer>
      </td>
    );
  };

  onProcessModalClose = (e) => {
    e.stopPropagation();
    this.setState({ setAddeditModalOpen: false });
  };

  render() {
    if (this.state.route != null) {
      return <Navigate to={this.state.route} />;
    }
    return (
      <>
        <div className="main-body-section">
          <div className="bg-white pl-8 pt-10 pb-12">
            <div className="grid grid-cols-12 gap-6 w-full items-center">
              <div className="2xl:col-span-6 lg:col-span-6 col-span-12 pr-7">
                <h2 className="text-xl theme-color">Date</h2>
                <div className="grid grid-cols-12 gap-6 items-center w-full">
                  <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                    <input
                      id="default"
                      name="dateFrom"
                      className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                      type="date"
                      value={this.state.pagination.dateFrom}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </div>
                  <div className=" 2xl:col-span-2 lg:col-span-2 col-span-12 w-full text-center">
                    <h2 className="text-xl theme-color">To</h2>
                  </div>
                  <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                    <input
                      id="default"
                      name="dateTo"
                      className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                      type="date"
                      value={this.state.pagination.dateTo}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                <h2 className="pl-12 block text-xl font-medium  theme-color">
                  Source
                </h2>
                <div className="pl-12 border-l-[1px] border-[#757575]">
                  <DropdownSelect
                    drpIdentity={"sourceFilter"}
                    optionArray={this.state.sourceList}
                    setFilterParameters={this.setFilterParametersForList.bind(
                      this
                    )}
                    value={this.state.pagination.filterBySource}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-6 gap-4 pt-10 px-8">
            <h2 className="col-start-1 col-end-3 text-3xl font-bold text-[#757575]">
              {`Total:${this.state.totalResultes}`}
            </h2>
            <div className="col-end-7 col-span-3 grid grid-cols-3 items-center">
              <div className="text-[#757575] text-xl">Search by Company </div>
              {/* <input className="col-span-2 text-lg font-bold  h-[40px] w-full text-[#757575]"/> */}
              <input
                id="default"
                className="col-span-2 text-lg font-bold  h-[55px] w-full text-[#757575] border-white"
                type="text"
                onChange={(e) => this.searchByName(e.target.value)}
              />
            </div>
          </div>
          <main className="pb-10">
            <div className="w-full mx-auto">
              <div className="relative">
                <div>
                  <div className=" mt-6">
                    <Table
                      columns={[
                        {
                          name: "registrationDate",
                          title: "Registration Date",
                        },
                        { name: "FullName", title: "Name" },
                        { name: "Email", title: "Email Address" },
                        { name: "CompanyName", title: "Company Name" },
                        // { name: "Industry", title: "Industry" },
                        { name: "CompanyUEN", title: "Company UEN" },
                        {
                          name: "transactionTypeName",
                          title: "Transaction Type",
                        },
                        { name: "source", title: "Source" },
                        { name: 'ApplicationStatusName', title: 'status' },
                        { name: 'pic', title: 'PIC' },
                        { name: "corporateMemberId", title: "Action" },
                      ]}
                      pagination={this.state.pagination}
                      rows={this.state.taskList}
                      sortingColumns={["source"]}
                      isLoading={this.state.isLoading}
                      totalResultes={this.state.totalResultes}
                      totalCount={this.state.totalResultes}
                      setPagination={this.setPagination.bind(this)}
                      customScope={[
                        {
                          column: "corporateMemberId",
                          renderTableData: this.actions.bind(this),
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <div className="member-task-process">
          <MembershipTaskListProcess
            handleChangeCheckBox={this.handleChangeCheckBox.bind(this)}
            handleChangeMembershipOnCheckBox={this.handleChangeMembershipOnCheckBox.bind(
              this
            )}
            setAddeditModalOpen={this.state.setAddeditModalOpen}
            membershipTypes={this.state.membershipTypes}
            transactionTypes={this.state.transactionTypes}
            billingCategories={this.state.billingCategories}
            membershipRevenueList={this.state.membershipRevenueList}
            sourceList={this.state.sourceList}
            users={this.state.users}
            tenureArr={this.state.tenureList}
            numberOfChapters={this.state.numberOfChapters}
            taskDetails={this.state.taskDetails}
            basePriceTable={this.state.basePriceTable}
            allowableDiscountTable={this.state.allowableDiscountTable}
            percentageDiscountTable={this.state.percentageDiscountTable}
            systemCalculatedTable={this.state.systemCalculatedTable}
            disablePaymentButton={this.state.disablePaymentButton}
            disableSubscription={this.state.disableSubscription}
            validState={this.state.validState}
            validStateForInvoice={this.state.validStateForInvoice}
            setModalOpen={() => this.setState({ setAddeditModalOpen: false })}
            handleChange={this.handleChangeInPopup.bind(this)}
            handleChangeInPriceTable={this.handleChangeInPriceTable.bind(this)}
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ setAddeditModalOpen: false });
            }}
            validateField={this.validateField.bind(this)}
            validateFieldForInvoice={this.validateFieldForInvoice.bind(this)}
            setFilterParameters={this.setFilterParameters.bind(this)}
            addEditMembershipTask={this.addEditMembershipTask.bind(this)}
            addEditMembershipTaskAndSendPaymentLink={this.addEditMembershipTaskAndSendPaymentLink.bind(
              this
            )}
            sendInvoiceEmailToCorporateMember={this.sendInvoiceEmailToCorporateMember.bind(
              this
            )}
            sendQuotationEmailToCorporateMember={this.sendQuotationEmailToCorporateMember.bind(
              this
            )}
            sendEscalateMailToMember={this.sendEscalateMailToMember.bind(this)}
            closeModalOnBack={() => {
              this.setState({ setAddeditModalOpen: false });
            }}
            isLoading={this.state.isLoading}
            isLoadingApprove={this.state.isLoadingApprove}
            isLoadingPayment={this.state.isLoadingPayment}
            isLoadingQuotation={this.state.isLoadingQuotation}
            isLoadingInvoice={this.state.isLoadingInvoice}
            isEscalateLodaing={this.state.isEscalateLodaing}
            handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(
              this
            )}
            designationList={this.state.designationList}
            tacNameList={this.state.tacNameList}
            interestedChapter= {this.state.interestedChapter}
          />
        </div>
        <div className="membership-task-list-process-sec">
          <SendForApprove
            companyName={this.state.taskDetails.companyName}
            email={this.state.taskDetails.email}
            setSendApproveModal={this.state.setSendApprove}
            setOpenModal={() => this.setState({ setSendApprove: false })}
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ setSendApprove: false });
            }}
          />
          <SendPaymentLink
            companyName={this.state.taskDetails.companyName}
            email={this.state.taskDetails.email}
            paymentLinkModal={this.state.setPaymentLink}
            setOpenModal={() => this.setState({ setPaymentLink: false })}
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ setPaymentLink: false });
            }}
          />
          <SendQuotation
            companyName={this.state.taskDetails.companyName}
            email={this.state.taskDetails.email}
            setSendQuotationModal={this.state.sendQuotation}
            setOpenModal={() => this.setState({ sendQuotation: false })}
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ sendQuotation: false });
            }}
          />
          <SendInvoice
            companyName={this.state.taskDetails.companyName}
            email={this.state.taskDetails.email}
            setSendInvoiceModal={this.state.sendInvoice}
            setOpenModal={() => this.setState({ sendInvoice: false })}
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ sendInvoice: false });
            }}
          />
          <EscalateModal
            companyName={this.state.taskDetails.companyName}
            email={this.state.taskDetails.email}
            setSendInvoiceModal={this.state.sendEscalation}
            setOpenModal={() => this.setState({ sendEscalation: false })}
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ sendEscalation: false });
            }}
          />
        </div>
      </>
    );
  }
}
